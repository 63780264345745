import styles from "./styles.module.css";
export default function Navbar() {
  let pathname = window.location.pathname;

  let links = [];

  if (pathname === "/") {
    links = [
      ["/about-us", "About"],
      ["/service", "Service"],
    ];
  } else if (pathname === "/about-us") {
    links = [
      ["/", "Home"],
      ["/service", "Service"],
    ];
  } else if (pathname === "/service") {
    links = [
      ["/", "Home"],
      ["/about-us", "About"],
    ];
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.headerTitle}>
        <img
          className={styles.headerLogo}
          src="/yahablefiresafetylogo.png"
          alt=""
        />
        <h5>Yahable Fire Safety & Consulting</h5>
      </div>

      <div className={styles.links}>
        {links.map((link) => {
          return (
            <a key={link[1]} href={link[0]}>
              {link[1]}
            </a>
          );
        })}
      </div>
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: ".1em",
        }}
        className={styles.book}
        href="https://wa.link/usf0r5"
        target="_blank"
        rel="noopener noreferrer"
      >
        BOOK CONSULTING
        <img height={25} width={25} src="/homeIcons/circleIcon.png" alt="" />
      </a>
    </nav>
  );
}
