import styles from "./styles.module.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer class={styles.footer}>
      <p>
        <span> YAHABLE FIRE </span> SAFETY & CONSULTING
      </p>
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: ".6em",
          gap: ".1em",
        }}
        href="https://mail.google.com/mail/?view=cm&fs=1&to=nwobododominic45@gmail.com&su=Fire+Safety+Consultation"
        target="_blank"
        rel="noopener noreferrer"
      >
        CONTACT US
        <img height={20} width={20} src="/homeIcons/circleIcon.png" alt="" />
      </a>
      &copy; {currentYear}. All rights reserved.
    </footer>
  );
}

export default Footer;
