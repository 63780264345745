import introImage from "../assets/intro.jpg";
import locationImage from "../assets/location.png";
import styles from "./page.module.css";
import image1 from "./home/home1.jpg";
import image2 from "./home/home2.jpg";
import image3 from "./home/home3.jpg";
import image4 from "./home/home4.jpg";
import image5 from "./home/home5.jpg";
function Home() {
  return (
    <main class={styles.page}>
      <div>
        <h1 class={styles.header}>
          Prioritizing Preventive Fire Hazards Measures with{" "}
          <span> Yahable Fire Safety And Consultation Ltd </span>
        </h1>
        <small>
          The Safety And Well-Being Of The Community Is Our Priority
        </small>
      </div>

      <div class={styles.imageContainer}>
        <img alt="" className={styles.heroImage} src={introImage} />
      </div>

      <p class={styles.locationText}>
        <img src={locationImage} alt="" /> Located At:{" "}
        <span>
          Kuje, Federal Capital Territory. <em>NIGERIA</em>
        </span>
      </p>

      <section className={styles.sectionContainer}>
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
        <img src={image5} alt="" />
      </section>
    </main>
  );
}

export default Home;
