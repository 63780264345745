import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  useEffect(() => navigate("/"), [navigate]);
  return (
    <div>
      <h1>Page not available</h1>
      <NavLink to="/">Return to the home page</NavLink>
    </div>
  );
}

export default Error;
