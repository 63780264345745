import styles from "./styles.module.css";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
const TextWriter = ({ text }) => {
  const textVariants = {
    hidden: { opacity: 0 },
    visible: (i) => ({
      opacity: 1,
      y: 0,

      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 50,
      },
    }),
  };

  return (
    <p>
      {text.split("").map((char, index) => (
        <motion.span
          key={index}
          custom={index}
          variants={textVariants}
          initial="hidden"
          animate="visible"
        >
          {char}
        </motion.span>
      ))}
    </p>
  );
};

function Service() {
  const service1 = [
    ["Safety Training", "safetyTraining"],
    ["Marine Fire Safety Service", "marineFireSafetyService"],
    ["Supply Of Safety Appliances And Merchandise", "safetyGadget"],
    ["Contract Management", "consultationTeam"],
  ];
  const service2 = ["item1", "item2", "item3", "item4"];

  const [width, setWidth] = useState(0);

  const element = useRef();
  useEffect(() => {
    setInterval(() => {
      if (element.current) {
        setWidth(element.current.clientWidth);
      }
    }, 700);
  }, [width]);

  const service = () => {
    return (
      <div className={styles.serviceContainer}>
        {service1.map((service) => {
          return width > 860 ? (
            <motion.div key={service[0]} className={styles.card}>
              <TextWriter text={service[0]} />
              <img
                width={300}
                height={300}
                src={`/services/${service[1]}.jpg`}
                alt={service[1]}
              />
            </motion.div>
          ) : (
            <motion.div key={service[0]} className={styles.card}>
              <img
                width={300}
                height={300}
                src={`/services/${service[1]}.jpg`}
                alt={service[1]}
              />
              <TextWriter text={service[0]} />
            </motion.div>
          );
        })}
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <h3
        className={styles.serviceHeader}
        ref={element}
        style={{ textAlign: "center", margin: "2rem 0" }}
      >
        <span>Yahable Fire Safety & Consulting Ltd </span> Provides
      </h3>
      {service()}
      <h3 className={styles.installationHeader}>
        We Also Provide Fire Safety Installation And Servicing
      </h3>
      <div className={styles.serviceContainer}>
        {service2.map((service) => (
          <motion.div key={service} className={styles.card}>
            <img
              width={300}
              height={300}
              src={`/services/${service}.jpg`}
              alt={service}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Service;
